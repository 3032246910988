import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ContactForm from '../../components/contact/ContactForm';
import withTranslations from '../../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Layout section={'CONTACT'}>
      <Seo
        title={t('contact.seoTitle')}
        description={t('contact.seoDescription')}
        keywords={['contacto positive zero']}
      />
      <ContactForm />
    </Layout>
  );
};

export default withTranslations()(Contact);
